// imports
import $ from 'jquery';
import './components/modal.js';
const jQuery = $;

// make jquery accessable ouside of webpack compiled js
// DO NOT REMOVE, SUPER DUPER IMPORTANT
window.$ = $;
window.jQuery = jQuery;


jQuery(document).ready(function($) {

  $('[href="#faq-role"]').click(function(e) {
    $("#faq-role")
        .addClass("active")
        .siblings()
        .removeClass("active");
  });
  
  $(".faq-title").click(function(e) {
    // $(this).siblings('.faq-content').slideToggle();
    $(this)
      .parents(".faq-item")
      .siblings()
      .removeClass("active");
    $(this)
      .parents(".faq-item")
      .toggleClass("active");
    console.log("faq clicked");
  });
  
  //main video
  $('[data-toggle="video-in-place"]').click(function(e) {
    e.preventDefault();
    var target = $($(this).attr('href'));
    target.toggleClass('active');
    
    loadVideo(target);
  });
  
  function loadVideo(container) {
    unloadVideo();
    closeModals();
    var videoContainer = container.find('[data-vidyard-id]');
    if(videoContainer) {
      videoContainer.addClass('active');
      
  
      var videoID = videoContainer.attr("data-vidyard-id");
      var html =
        '<script type="text/javascript" id="vidyard_embed_code_' +
        videoID +
        '" src="//play.vidyard.com/' +
        videoID +
        '.js?v=3.1.1&type=inline&autoplay=1"></script>';
      videoContainer.html(html);
    }
  }
  
  function unloadVideo() {
    $('[data-vidyard-id]')?.removeClass('active').html('');
  }
  
  function closeModals() {
    $('[data-modal]').removeClass('active');
  }

});
