import jQuery from 'jquery';

jQuery(document).ready(function($) {
  $('[data-toggle="modal"]').click(function(e) {
    e.preventDefault();
    unloadVideo();
    var target = $($(this).attr('href'));
    target.toggleClass('active');
    
    loadVideo(target);
  });
  
  $('[data-close="modal"]').click(function(e) {
    e.preventDefault();

    $('[data-modal]').removeClass('active no-transition');
    unloadVideo()
    $('[data-modal]').each(function() {
      var html = $(this).find('.modal-body').html();
      $(this).find('.modal-body').html(html);
    });
  });

  $(document).keyup(function(e){
    if(e.keyCode === 27) {
      $('[data-modal]').removeClass('active no-transition');
      unloadVideo();
    }
  });
});

function loadVideo(modal) {
    var videoContainer = modal.find('[data-vidyard-id]');
    if(videoContainer){
        if(modal.hasClass('active')) {
            var videoID = videoContainer.attr("data-vidyard-id");
            var html =
              '<script type="text/javascript" id="vidyard_embed_code_' +
              videoID +
              '" src="//play.vidyard.com/' +
              videoID +
              '.js?v=3.1.1&type=inline&autoplay=1"></script>';
            videoContainer.html(html);
        }
        else {
            console.log('remove')
            videoContainer.html('');
        }
    }
}

function unloadVideo() {
    jQuery('[data-vidyard-id]')?.removeClass('active').html('');
}